import { MEMBERSHIPS_IMAGE_URL, MEMBERSHIPS_TITLE } from 'enums/memberships'
import {
  ChargeableTitle,
  DEFAULT_IMAGE,
  DEFAULT_TITLES,
  DEFAULT_TITLE_TO_IMAGE_KEY
} from 'enums/topLevelChargeables'
import determineImageFromSchedulable from 'shared/helpers/determineImageFromSchedulable'
import { OutletPresenter } from 'shared/presenters'
import { Outlets, Schedulables } from 'shared/presenters/graphqlTypes'
import { RecursivePartial } from 'shared/presenters/presenter'
import { generateCloudinaryUrl } from 'shared/helpers/generateCloudinaryUrl'
import { VOUCHERS_IMAGE_URL, VOUCHERS_TITLE } from 'enums/vouchers'

/**
 * The currently fallback image is meant mostly for activities. This variable helps to determine if
 * a different chargeable needs to overwrite this default image.
 */
const CUSTOM_FALLBACK_IMAGE: Partial<Record<ChargeableTitle, string>> = {
  [MEMBERSHIPS_TITLE]: MEMBERSHIPS_IMAGE_URL,
  [VOUCHERS_TITLE]: VOUCHERS_IMAGE_URL
}

/**
 * On the home page we show UI 'cards' to represent categories/groups of chargeables
 * This function is for determining the most appropriate image to use in the CoverImage for that card
 */
const determineTopLevelChargeableImage = ({
  outlet,
  schedulable,
  title
}: {
  outlet: Outlets | OutletPresenter
  schedulable?: RecursivePartial<Schedulables>
  title?: string
}) => {
  const defaultTitle = DEFAULT_TITLES.filter(x => x === title)[0]
  const imageKey = defaultTitle && DEFAULT_TITLE_TO_IMAGE_KEY[defaultTitle]
  const customFallbackImage =
    defaultTitle && CUSTOM_FALLBACK_IMAGE[defaultTitle]
  const imageKeySrc = imageKey && outlet[imageKey]

  // use key image if applicable
  if (imageKeySrc) return generateCloudinaryUrl(imageKeySrc, 'fullscreen')

  // fallback to image taken from schedulable if a schedulable is provided
  const imageFromSchedulable = determineImageFromSchedulable({
    image: schedulable?.image,
    defaultImage: schedulable?.default_image,
    activityType: schedulable?.activity_type
  })

  if (schedulable && imageFromSchedulable) return imageFromSchedulable

  // fallback to custom fallback image
  if (customFallbackImage) return customFallbackImage

  return DEFAULT_IMAGE
}

export default determineTopLevelChargeableImage
